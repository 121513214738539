import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ResType {
  FUNCTION = 'FUNCTION',
  MENU = 'MENU'
}

export interface NavItem {
  pkMenu: string; // 菜单 MD5 标识
  menuCode: string; // 菜单 code 标识
  menuName: string; // 菜单名称
  pkParent: string; // 父级菜单标识
  // resType?: ResType;
  menuUrl?: string;
  icon?: string;
  children?: NavItem[]; // 子菜单集合
  parent?: NavItem; // 父菜单
  selected?: boolean;
  [x: string]: any
}

export interface NavServiceData {
selectItem: NavItem;
toggled: boolean;
navTrack: NavItem[];
unreadMap: { [key: string]: number };
}


@Injectable({
  providedIn: 'root'
})
export class NavService {
  [x: string]: any;
  public navToggle: Subject<any> = new Subject();
  public linkList: NavItem[] = [];
  public linkTree: NavItem[] = [];
  public linkMap: Map<string, NavItem> = new Map();
  public navData: NavServiceData = {
    selectItem: null,
    toggled: false,
    navTrack: [],
    unreadMap: {}
  };
  set originLinks(menus: NavItem[]) {
    const tree: NavItem[] = [];
    const map: Map<string, NavItem> = new Map();
    menus.map(item => {
      item.selected = false;
      map.set(item.pkMenu, item);
    });
    menus.map(item => {
      if (item.pkParent) {
        const parentItem = map.get(item.pkParent);
        if (parentItem) {
          item.parent = parentItem;
          if (parentItem.children) {
            parentItem.children.push(item);
          } else {
            parentItem.children = [item];
          }
        } else {
          tree.push(item);
        }
      } else {
        tree.push(item);
      }
    });

    const fnSort = (a: NavItem, b: NavItem) => (a.menuCode).localeCompare(b.menuCode);
    function sorItems(arr: NavItem[]): void {
      arr.sort(fnSort);
      arr.map(item => {
        if (item.children) { sorItems(item.children); }
      });
    }
    sorItems(tree);
    this.linkList = menus;
    this.linkTree = tree;
    this.linkMap = map;
  }
  setSelectItem(selectItem: NavItem) {
    this.navData.selectItem = selectItem;
  }
  constructor(
  ) {
  }
  createTrack(item: NavItem) {
    const arr: NavItem[] = [];
    const loop = (levelNav: NavItem) => {
      if (levelNav) {
        arr.unshift(levelNav);
        if (levelNav.parent) loop(levelNav.parent);
      }
    };
    loop(item);
    this.navData.navTrack = arr;
  }
  getTracks(item: NavItem) {
    const t = [item];
    const insertParent = (ni: NavItem, tracks: NavItem[]) => {
      if (ni && ni.parent) {
        tracks.unshift(ni.parent);
        insertParent(ni.parent, tracks);
      }
    };
    insertParent(item, t);
    return t;
  }

  search(keyword: string) {
    const reg = new RegExp(keyword);
    return this.linkList.filter(i => reg.test(i.menuName));
  }
}
