import { Injectable, InjectionToken, Injector } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { NotificationComponent } from './notification.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationConfig, NotificationType } from './notification.model';
import { notificationInjectToken } from './notificationIn.jectToken';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private messageID = 0;
  private overlayRef: OverlayRef;
  private messageList: any = [];
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private sanitizer: DomSanitizer
  ) {
    this.overlayRef = this.overlay.create();
  }


  public error(msg: any, config?: NotificationConfig) {
    return this._newMessage(msg, NotificationType.error, config);
  }
  public info(msg: any, config?: NotificationConfig) {
    return this._newMessage(msg, NotificationType.info, config);
  }
  public success(msg: any, config?: NotificationConfig) {
    return this._newMessage(msg, NotificationType.success, config);
  }
  public warn(msg: any, config?: NotificationConfig) {
    return this._newMessage(msg, NotificationType.warn, config);
  }
  public primary(msg: any, config?: NotificationConfig) {
    return this._newMessage(msg, NotificationType.primary, config);
  }

  private _newMessage(msg: any, type: NotificationType, config: NotificationConfig): Promise<void> {
    if (config === undefined) {
      config = {};
    }
    this._initMessageContainer();
    return new Promise((resolve, reject) => {
      const obj = {
        id: this.messageID++,
        message: this.sanitizer.bypassSecurityTrustHtml(typeof msg === 'string' ? msg : JSON.stringify(msg)),
        type,
        timmer: null,
        showIcon: config.showIcon,
        resolve,
        className: `notification-list-item notification-${NotificationType[type]}`
      };
      this.messageList.unshift(obj);
      if (config.autoClose !== false) {
        obj.timmer = setTimeout(() => {
          this._removeMsg(obj.id);
          obj.resolve();
        }, config.duration || 4000);
      }
    });
  }


  private _removeMsg(id: number) {
    let index = null;
    this.messageList.every((item, i) => {
      if (item.id === id) {
        index = i;
      }
      return item.id !== id;
    });
    if (index !== null) {
      (this.messageList.splice(index, 1));
    }
  }


  private _initMessageContainer() {
    if (this.overlayRef.hasAttached()) { return; }
    const injectionTokens = new WeakMap();
    injectionTokens.set(notificationInjectToken, { messageList: this.messageList });
    const portalInjector = new PortalInjector(this.injector, injectionTokens);
    const componentPortal = new ComponentPortal(NotificationComponent, undefined, portalInjector);
    this.overlayRef.attach(componentPortal);
  }

}
