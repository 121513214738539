import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { MatButtonModule } from '@angular/material/button';
import { NotificationService } from './notification.service';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    MatButtonModule
  ],
  declarations: [NotificationComponent],
  entryComponents: [NotificationComponent],
  providers: [NotificationService]
})
export class NotificationModule { }
