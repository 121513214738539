import { Injectable } from '@angular/core';
import { UserApiService } from '../api/user-api.service';
import { Subject, Observable } from 'rxjs';
import { NavItem, NavService } from '../nav.service';
import { LoginCallback, LoginFormModel, UserModel } from 'src/app/models/User.model';
import { HttpResult } from 'src/app/models/Http.model';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public signOutEvent = new Subject();
  user: UserModel;
  constructor(
    private userApi: UserApiService,
    private navService: NavService
  ) {}

  signin(params:LoginFormModel ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userApi.signin(params).subscribe((result: HttpResult<LoginCallback>) => {
        localStorage.setItem('USER_INFO', JSON.stringify(result.data));
        this.user = result.data;
        resolve(result.data);
      }, (err) => {
        reject(err);
      });
    });
  }


  // 退出登录
  signOut(): Promise<void> {
    return new Promise((resolve) => {
      // this.userApi.signOut().subscribe(() => {
      //   this.signOutCleanActiion();
      //   resolve();
      // }, (e) => {
      //   this.signOutCleanActiion();
      //   resolve();
      // });
      try {
        localStorage.clear();
        resolve();
      } catch(e) {
        resolve();
      }
    });
  }


  // 初始化用户信息
  initializeUserInfo(params = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      // if (this.userInfoObservable == null) {
      //   this.userInfoObservable = this.userApi.getUserInfo().pipe(
      //     shareReplay(),
      //     map(_ => JSON.parse(JSON.stringify(_)))
      //   );
      // }
      // this.userInfoObservable.subscribe((result: any) => {
      //   this.user = result.data;
      //   resolve();
      // }, (err) => {
      //   reject(err);
      // });
      try {
        let data = localStorage.getItem('USER_INFO');
        this.user = data ? JSON.parse(data) : null;
        resolve();
      } catch(e) {
        reject();
      }
    });
  }


  // 用户是否已登录
  get signedIn() {
    return !!this.user;
  }

  getMenus(params = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userApi.getUserMenu(params).pipe(
      ).subscribe((result:NavItem[]) => {
        this.navService.originLinks = result;
        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }
}
