<ul class="notification-list-box">
  <ng-template ngFor let-item [ngForOf]="messageList" let-i="index">
    <li [@FlyIn] class="notification-list-line">
      <div class="notification-list-item" [class]="item.className">
        <div class="notification-item-icon" [ngSwitch]="item.type">
          <i *ngSwitchCase="type.error" class="iconfont iconclose"></i>
          <i *ngSwitchCase="type.info" class=""></i>
          <i *ngSwitchCase="type.success" class="iconfont icondui12"></i>
          <i *ngSwitchCase="type.warn" class=""></i>
          <i *ngSwitchCase="type.primary" class=""></i>
        </div>
        <span [innerHTML]="item.message"></span>
        <button mat-icon-button class="notification-close-btn" (click)="clearMe(item)">
          <i class="iconfont iconclose"></i>
        </button>
      </div>
    </li>
  </ng-template>
</ul>