import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingControl {

  private loadingMap: { [loadingName: string]: number[] } = Object.create(null);
  public status: { [loadingName: string]: boolean } = Object.create(null);

  public showLoading(loadingName: any, timeStamp: number) {
    if (loadingName !== null) {
      // tslint:disable-next-line: no-unused-expression
      this.loadingMap[loadingName] === undefined && (this.loadingMap[loadingName] = []);
      this.loadingMap[loadingName].push(timeStamp);
      this._checkLoading(loadingName);
    }
  }


  public hideLoading(loadingName, timeStamp) {
    if (loadingName !== null) {
      this.loadingMap[loadingName] = (this.loadingMap[loadingName] || []).filter((t => t !== timeStamp));
      this._checkLoading(loadingName);
    }
  }

  public clearAllLoading() {
    Object.keys(this.loadingMap).forEach((key) => {
      delete this.loadingMap[key];
    });
    Object.keys(this.status).forEach((key) => {
      delete this.status[key];
    });
  }

  public createTimeStamp() {
    return new Date().getTime() * 10000 + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
  }


  private _checkLoading(loadingName) {
    this.status[loadingName] = this.loadingMap[loadingName].length !== 0;
  }



}
