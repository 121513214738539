
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpInterceptorService } from './HttpInterceptor.service';
import { OperationXitongApiService } from './operation-xitong-api.service';
import { OperationYewuApiService } from './operation-yewu-api.service';


@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    OperationYewuApiService,
    OperationXitongApiService
  ]
})
export class ApiModule { }
