import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpRequestList, HttpResult, DataListResult } from "src/app/models/Http.model";
import { SettleModel, CustomerModel, ShouShiZheModel, ProjectModel, CenterModel } from "src/app/models/Yewu.model";

@Injectable({
  providedIn: 'root'
})
export class OperationYewuApiService {

constructor(
  private http: HttpClient
) { }
// 首页
  getDashboardData() {
    return this.http.post('/api/op/homePage', {}) as Observable<HttpResult<{
      customerCount: number,
      projectsCount: number,
      subjectsCount: number,
      totalSettledMny: number
    }>>
  }

// 客户

  getCustomerList(params: HttpRequestList = {}) {
    return this.http.post('/api/op/customer/getByPage', Object.assign({ _loading: 'getCustomerList' }, params)) as Observable<HttpResult<DataListResult<CustomerModel>>>;
  }

  getCustomer(params) {
    return this.http.post(`/api/op/customer/getDetail`, Object.assign(params, { _loading: 'getCustomer' })) as Observable<HttpResult<CustomerModel>>;
  }

  newCustomer(form) {
    return this.http.post(`/api/op/customer/add`, Object.assign({ _loading: 'newCustomer' }, form)) as Observable<HttpResult<any>>;
  }

  modifyCustomer(form) {
    return this.http.post(`/api/op/customer/edit`, Object.assign({ _loading: 'modifyCustomer' }, form)) as Observable<HttpResult<any>>;
  }

  resetCustomerPassword(params) {
    return this.http.post(`/api/op/customer/resetPwd`, Object.assign({ _loading: 'resetCustomerPassword' }, params)) as Observable<HttpResult<any>>;
  }

// 项目  

  getProjectList(params: HttpRequestList = {}) {
    return this.http.post('/api/op/customer/project/getByPage', Object.assign({ _loading: 'getProjectList' }, params)) as Observable<HttpResult<DataListResult<ProjectModel>>>;
  }

  getProject(params) {
    return this.http.post(`/api/op/customer/project/getDetail`, Object.assign(params, { _loading: 'getProject' })) as Observable<HttpResult<ProjectModel>>;
  }
// 中心
  getCenterList(params: HttpRequestList = {}) {
    return this.http.post('/api/op/center/getByPage', Object.assign({ _loading: 'getCenterList' }, params)) as Observable<HttpResult<DataListResult<CenterModel>>>;
  }

// 受试者

  getSubjectList(params: HttpRequestList = {}) {
    return this.http.post('/api/op/customer/subjects/getByPage', Object.assign({ _loading: 'getSubjectList' }, params)) as Observable<HttpResult<DataListResult<ShouShiZheModel>>>;
  }

  getSubject(params) {
    return this.http.post(`/api/op/customer/subjects/getDetail`, Object.assign({ _loading: 'getSubject' }, params)) as Observable<HttpResult<ShouShiZheModel>>;
  }
// 结算单

  getSettleList(params: HttpRequestList = {}) {
    return this.http.post('/api/op/customer/settle/getByPage', Object.assign({ _loading: 'getSettleList' }, params)) as Observable<HttpResult<DataListResult<SettleModel>>>;
  }

  getSettle(params) {
    return this.http.post(`/api/op/customer/settle/getDetail`, Object.assign({ _loading: 'getSettle' }, params)) as Observable<HttpResult<SettleModel>>;
  }

  paySuccess(params) {
    return this.http.post(`/api/op/customer/settle/paySuccess`, Object.assign({ _loading: 'paySuccess' }, params)) as Observable<HttpResult<any>>;
  }

  payFail(params) {
    return this.http.post(`/api/op/customer/settle/payFail`, Object.assign({ _loading: 'payFail' }, params)) as Observable<HttpResult<any>>;
  }

  // exportSettle(params) {
  //   return this.http.post(`/api/op/settle/export`, Object.assign({ _loading: 'exportSettle' }, params), {
  //     headers: {
  //       'Content-Type': 'application/vnd.ms-excel'
  //     }
  //   }) as Observable<HttpResult<any>>;
  // }
   /**
   * 导出地址
   */
    getSettleExportUrl() {
      return `/api/op/settle/export`;
    }

  // 批量结算
  batchPaySuccess(params) {
    return this.http.post(`/api/op/customer/settle/batchPaySuccess`, Object.assign({ _loading: 'batchPaySuccess' }, params)) as Observable<HttpResult<SettleModel>>;
  }
}