import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, tap, map } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { LoadingControl } from '../loading-control';
import { NotificationService } from 'src/app/components/notification';

@Injectable()
export class HttpInterceptorService {

    constructor(
        private loadingSer: LoadingControl,
        private router: Router,
        private notification: NotificationService,
        private userService: UserService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { loadingName, cloneReq, ignoreError } = this.filterRequest(req);
        let timeStamp;
        if (loadingName !== null) {
            timeStamp = this.loadingSer.createTimeStamp();
            this.loadingSer.showLoading(loadingName, timeStamp);
        }
        return next.handle(cloneReq).pipe(
            map((event) => {
                if (event instanceof HttpResponse) {
                    if (Object.prototype.toString.apply(event.body) === '[object Object]') {
                        const status = event.body.status;
                        if (status !== 0|| status === null || status === '' || status === undefined) {
                          throw new HttpErrorResponse({
                                status: 500,
                                error: {
                                  status,
                                  msg: event.body.statusInfo
                                }
                            });
                        }
                    }
                }
                return event;
            }),
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
            }, (errRes: any) => {
                if (!ignoreError) {
                    switch (errRes.status) {
                        case 401:
                            this.notification.error('请登录后访问！');
                            this.router.navigate(['/login']);
                            break;
                        case 403:
                            this.notification.error('无权限访问');
                            break;
                        case 404:
                            this.notification.error('服务不存在');
                            break;
                        case 500:
                            let errMsg = '服务器异常 稍后重试';
                            if (Object.prototype.toString.apply(errRes.error) === '[object Object]') {
                                if (errRes.error.msg) {
                                    errMsg = errRes.error.msg;
                                }
                            }
                            if (errRes.error.status != 401) this.notification.error(errMsg);
                            break;
                        case 502:
                        case 504:
                            this.notification.error('服务暂不可用 稍后重试');

                            break;
                        default:
                            this.notification.error('服务器异常 稍后重试');

                    }
                }
                if (errRes.status === 500 && errRes.error.status == 401) {
                        this.router.navigate(['/login']);
                }
            }),
            finalize(() => {
                // tslint:disable-next-line: no-unused-expression
                loadingName !== null && this.loadingSer.hideLoading(loadingName, timeStamp);
            })
        );
    }
    private filterRequest(req: HttpRequest<any>) {
        let cloneReq = req;
        let loadingName = null;
        let ignoreError = false;
        const settingHeader: any = { 
            authorization: this.userService?.user?.token || '',
            client: 'OP'
         };

        req.headers.keys().forEach(_ => settingHeader[_] = req.headers.get(_));


        const headers = new HttpHeaders(settingHeader);
        switch (req.method) {
            case 'GET':
            case 'DELETE':
                if (req.params.has('_loading')) {
                    loadingName = req.params.get('_loading').toString();

                    cloneReq = req.clone({
                        url: req.url,
                        params: req.params.delete('_loading').append('_timestamp_', new Date().getTime() + ''),
                        headers
                    });

                } else if (req.params.has('_ignoreError')) {
                    ignoreError = req.params.get('_ignoreError') as any === true;
                    cloneReq = cloneReq.clone({
                        url: req.url,
                        params: req.params.delete('_ignoreError').append('_timestamp_', new Date().getTime() + ''),
                        headers
                    });
                } else {
                    cloneReq = req.clone({
                        url: req.url,
                        params: req.params.append('_timestamp_', new Date().getTime() + ''),
                        headers
                    });
                }
                break;
            case 'POST':
            case 'PUT':
                if (req.body._loading !== undefined) {
                    loadingName = (req.body._loading).toString();
                    req.body._loading = undefined;
                }
                if (req.body._ignoreError !== undefined) {
                    ignoreError = req.body._ignoreError === true;
                    req.body._ignoreError = undefined;
                }
                req.body._ignorePkOrg = undefined;
                cloneReq = req.clone({
                    url: req.url,
                    headers,
                    params: req.params.append('_timestamp_', new Date().getTime() + '')
                });
                break;
        }
        return {
            loadingName,
            cloneReq,
            ignoreError
        };
    }

}
