import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpResult } from 'src/app/models/Http.model';
import { LoginCallback, LoginFormModel, UserModel } from 'src/app/models/User.model';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
    private http: HttpClient
  ) { }

  // 用户登录
  signin(loginForm: LoginFormModel) {
    return this.http.post('/api/pass/login', Object.assign(loginForm ,{ _loading: 'dologin' })) as Observable<HttpResult<LoginCallback>>;
  }
  // 修改密码
  changePWD(form) {
    return this.http.post('/api/auth/changePassword', Object.assign(form ,{ _loading: 'changePWD' })) as Observable<HttpResult<any>>;
  }

  // 获取当前登录用户的信息
  // getUserInfo(): Observable<HttpResult<UserModel>> {
  //   return this.http.post('/api/authentication/user/findUserByLogin', {_ignoreError: true}) as Observable<HttpResult<UserModel>>;
  // }

  getUserMenu(params = {}) {
    // return this.http.get('/assets/data/nav-menu.json')
    return of(
      [
        {"pkMenu":"3F3099F88D2C4326854AE64E720FBE5C","menuCode":"OP_MENU_01","menuName":"首页","menuUrl":"/operation/dashboard","icon":"","pkParent":null,"resType":"MENU"},
        {"pkMenu":"07D1F0DAB823401BB034DF17846B6FE5","pkSystem":"SCM_OP","menuCode":"OP_MENU_02","menuName":"业务管理","menuUrl":"/operation/yewu","icon":"","pkParent":null,"resType":"MENU"},
        {"pkMenu":"C70AEFA18EB74388BB4774DE35F06851","pkSystem":"SCM_OP","menuCode":"OP_MENU_0201","menuName":"客户管理","menuUrl":"/operation/yewu/kehu/list","icon":"icon-qklkehu","pkParent":"07D1F0DAB823401BB034DF17846B6FE5","resType":"MENU"},
        {"pkMenu":"C70AEFA18EB74388BB4774DE35F06852","pkSystem":"SCM_OP","menuCode":"OP_MENU_0202","menuName":"项目管理","menuUrl":"/operation/yewu/xiangmu/list","icon":"icon-qklxiangmu","pkParent":"07D1F0DAB823401BB034DF17846B6FE5","resType":"MENU"},
        {"pkMenu":"C70AEFA18EB74388BB4774DE35F06853","pkSystem":"SCM_OP","menuCode":"OP_MENU_0203","menuName":"受试者管理","menuUrl":"/operation/yewu/shoushizhe/list","icon":"icon-qklshoushizhe","pkParent":"07D1F0DAB823401BB034DF17846B6FE5","resType":"MENU"},
        {"pkMenu":"C70AEFA18EB74388BB4774DE35F06854","pkSystem":"SCM_OP","menuCode":"OP_MENU_0204","menuName":"结算管理","menuUrl":"/operation/yewu/jiesuan/list","icon":"icon-qkljiesuanguanli","pkParent":"07D1F0DAB823401BB034DF17846B6FE5","resType":"MENU"},
        {"pkMenu":"07D1F0DAB823401BB034DF17846B6FE6","pkSystem":"SCM_OP","menuCode":"OP_MENU_03","menuName":"系统管理","menuUrl":"/operation/xitong","icon":"","pkParent":null,"resType":"MENU"},
        {"pkMenu":"C70AEFA18EB74388BB4774DE35F06856","pkSystem":"SCM_OP","menuCode":"OP_MENU_0301","menuName":"帐号管理","menuUrl":"/operation/xitong/zhanghao/list","icon":"icon-qkluser-filling","pkParent":"07D1F0DAB823401BB034DF17846B6FE6","resType":"MENU"}]
    )
  }

  // 退出
  signOut() {
    return this.http.post('/api/authentication/user/logout', {});
  }

}
