import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpRequestList, HttpResult, DataListResult } from "src/app/models/Http.model";
import { UserModel } from "src/app/models/User.model";

@Injectable({
  providedIn: 'root'
})
export class OperationXitongApiService {

constructor(
  private http: HttpClient
) { }

    // 帐号

  getUserList(params: HttpRequestList = {}) {
    return this.http.post('/api/op/user/getByPage', Object.assign({ _loading: 'getUserList' }, params)) as Observable<HttpResult<DataListResult<UserModel>>>;
  }

  newUser(form) {
    return this.http.post(`/api/op/user/add`, Object.assign({ _loading: 'newUser' }, form)) as Observable<HttpResult<any>>;
  }

  resetUserPassword(form) {
    return this.http.post(`/api/op/user/resetPwd`, Object.assign({ _loading: 'resetUserPassword' }, form)) as Observable<HttpResult<any>>;
  }

  changeStaffStatus(form) {
    return this.http.post(`/api/op/user/toggle`, Object.assign({ _loading: 'changeStaffStatus' }, form)) as Observable<HttpResult<any>>;
  }
}