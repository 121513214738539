import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
  path: 'operation',
  loadChildren: () => import('./pages/operations/operations.module').then(m => m.OperationsModule)
},{
  path: 'login',
  loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
},
{ path: '', redirectTo: '/operation/dashboard', pathMatch: 'full' },
{ path: '**', redirectTo: '/operation/dashboard', pathMatch: 'full' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
